import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-73a3e530"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "order-sidebar" }
const _hoisted_2 = {
  key: 0,
  class: "sidebar-user"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "name" }
const _hoisted_5 = { class: "company" }
const _hoisted_6 = { class: "handler" }
const _hoisted_7 = { class: "sidebar-nav-group" }
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { class: "title" }
const _hoisted_10 = {
  key: 0,
  class: "prompt"
}

import { $apiContractPrompt } from '@/api/modules/payment';
import PaymentRoutes from '@/router/modules/PaymentRoutes';
import { ref, getCurrentInstance} from 'vue'
import { useRouter } from 'vue-router';

export default {
  setup(__props) {

const router = useRouter();
const routerList = PaymentRoutes.filter(item=>item.children).pop().children
const {proxy:{$filters:{concatImg}}} = getCurrentInstance();
const userInfo = ref(localStorage.getItem('user_info')?JSON.parse(localStorage.getItem('user_info')):'')
let contract_prompt = ref()
getContractPrompt()
async function getContractPrompt() {
  const { status, data} = await $apiContractPrompt({token:localStorage.getItem('token')})
  contract_prompt.value = status ? data : false
}

return (_ctx, _cache) => {
  const _component_router_link = _resolveComponent("router-link")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (userInfo.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("img", {
            src: _unref(concatImg)(userInfo.value.avatar||''),
            alt: "",
            class: "avatar"
          }, null, 8, _hoisted_3),
          _createElementVNode("span", _hoisted_4, _toDisplayString(userInfo.value.name || '--'), 1),
          _createElementVNode("span", _hoisted_5, _toDisplayString(userInfo.value.companyName || '--'), 1),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("button", {
              class: "btn",
              onClick: _cache[0] || (_cache[0] = $event => (_unref(router).push('/')))
            }, "退出")
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_7, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(routerList), (item) => {
        return (_openBlock(), _createBlock(_component_router_link, {
          to: item.path,
          key: item.path,
          class: "link"
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", {
              class: "iconfont icon",
              innerHTML: item.meta.icon
            }, null, 8, _hoisted_8),
            _createElementVNode("span", _hoisted_9, _toDisplayString(item.title), 1),
            (item.path =='/payment/contract'&&_unref(contract_prompt))
              ? (_openBlock(), _createElementBlock("p", _hoisted_10, _toDisplayString(_unref(contract_prompt)), 1))
              : _createCommentVNode("", true)
          ]),
          _: 2
        }, 1032, ["to"]))
      }), 128))
    ])
  ]))
}
}

}